import 'floating-vue/dist/style.css';
import 'remixicon/fonts/remixicon.css';
import '../css/app.css';
import './bootstrap';

import { createInertiaApp } from '@inertiajs/vue3';
import FloatingVue from 'floating-vue';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { modal } from 'momentum-modal';
import { createPinia } from 'pinia';
import { createApp, h } from 'vue';
import { ZiggyVue } from 'ziggy-js';

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) })
      .use(createPinia())
      .use(plugin)
      .use(ZiggyVue)
      .use(FloatingVue)
      .use(modal, {
        resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
      });

    app.config.devtools = process.env.NODE_ENV === 'development';

    app.mount(el);

    return app;
  },
});
